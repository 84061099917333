body,
html {
  height: 100%;
  display: grid;
}
#root {
  margin: auto;
}
.App {
  text-align: center;
}
p {
  margin-top: 0;
  font-size: 20px;
}
button {
  font-size: 20px;
  display: block;
  margin-top: 5px;
}